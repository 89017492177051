import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/layout'
import Img from 'gatsby-image'
import { graphql, Link } from 'gatsby'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import things_logo from '../../assets/images/projects/service/tech/things-logo.jpg'
import salone from '../../assets/images/projects/service/tech/Salone-del-Mobile_FNM.jpg'

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}

const ServiceDesignTNM = props => (
  <Layout>
    <Helmet>
      <title>Service Design - Tech no mad</title>
      <meta name="Service Design - Tech no mad" content="Service Design" />
    </Helmet>

    <div id="main" className="alt">
      <section id="work-banner">
        <div>
          <span className="image main">
            <Img fluid={props.data.tnmcover.childImageSharp.fluid} alt="" />
          </span>
        </div>
      </section>

      <div className="project-wrapper">
        <div className="project-content">
          <header>
            <span className="tag">RESARCH</span>
            <span className="tag">USER EXPERIENCE</span>
            <span className="tag">INTERACTION DESIGN</span>
            <span className="tag">SPATIAL DESIGN</span>
            <h1>
              tech.no.mad; <br />
              feeling at home everywhere.
            </h1>
            <h3>
              Concerns about data privacy and the use of personal information
              are becoming one of the most significant issues of out time.
              Mindlessly we often accept service conditions without really
              knowing which personal data we are sharing. We asked people to
              reflect on the value of their personal data, offering fictious
              services in exchange for different kinds of data. They were asked
              to agree-or-not to these exchanges.
            </h3>
          </header>
          <section className="header-post">
            <div className="headerpost">
              <div className="headerpost-left">
                <p>
                  * This project was carried out while working at the design
                  agency, THINGS, in Milan.
                </p>
                <p>
                  <a href="/say-hello">
                    <u>Get in touch</u>
                  </a>
                  with me for more details related to this project; such as my
                  role, the problems i faced, the processes i used and how i
                  addressed the challenges.
                </p>
              </div>
              <div className="headerpost-right">
                <div className="client-image">
                  {/* <Img fluid={props.data.things_logo.childImageSharp.fluid} /> */}
                  <img className="span-4" src={things_logo} alt="things logo" />
                </div>
                <p>
                  Things.is a design and innovation agency for the Internet of
                  Things. We create products & services with their own
                  experiential ecosystems outlined by multi-channel touch
                  points. We envision products and services in the IoT era
                  through a human-centred approach. www.things.is
                </p>
              </div>
            </div>
          </section>
          <section className="challenge-section">
            <span className="project-step-number">01</span>
            <h2>challenge</h2>
            <div className="challenge">
              <div className="challenge-desc">
                <p>
                  The aim was to investigate new scenarios of the digital
                  nomadic lifestyle through an exhibition setting. In addition,
                  create awareness about how this lifestyle is affecting the
                  design of physical products, services & spaces and affecting
                  human behaviours & relationship.
                </p>
                <p>
                  Awareness and concern about data privacy and specifically the
                  use of personal information by digital service providers and
                  communications channels is becoming one of the most
                  significant issues of our time. Services that draw on
                  multiple, real-time, personal data feeds - blended with
                  third-party information - inevitably raise questions around
                  privacy and ethics.
                </p>
              </div>
              <div className="challenge-credits">
                <div className="topbar" />
                <span className="credits-text">
                  <strong>YEAR:</strong> 2017
                </span>
                <span className="credits-text">
                  <strong>DURATION:</strong> 3 months
                </span>
                <span className="credits-text">
                  <strong>PROJECT TYPE:</strong> Designing an interactive
                  exhibition at BASE for the Salone del Mobile during the Milan
                  Design Week 2017.
                </span>
                <span className="credits-text">
                  <strong>TEAM MEMBERS:</strong> Design team of THINGS, Milan
                </span>
                <span className="credits-text">
                  <strong>MY ROLE:</strong> Desk research to gather inspiration
                  & benchmarking, in-person interviews of digital nomads.
                  Analysing of research to create personas and identify key
                  insights that drove the generation of ideas & main concept.
                  Formulating an exhibition experience that explore the
                  idea/concept. Exhibition design & execution of the design in
                  the physical space. Engaging with visitors during the
                  exhibition. Calculating the answers and creating an
                  infographic for visualising the results.
                </span>
                <div className="challenge-client">
                  <h5>CLIENT</h5>
                  <div className="client-image">
                    <img className="span-3" src={salone} alt="salone logo" />
                  </div>
                </div>
              </div>
            </div>
            <div className="two-sidebyside">
              <div className="img left">
                <Img
                  className="project-process-images"
                  fluid={props.data.tnmchallenge1.childImageSharp.fluid}
                  alt=""
                  title=""
                />
              </div>
              <div className="img right">
                <Img
                  className="project-process-images"
                  fluid={props.data.tnmchallenge2.childImageSharp.fluid}
                  alt=""
                  title=""
                />
              </div>
            </div>
            <p className="full-text">
              The challenge was to design an installation that will allow
              visitors to explore in a fun and interactive way how comfortable
              they feel in sharing personal data in different situations. The
              exhibition will also showcase several interactive products,
              prototypes and concepts to explore the different aspects &
              scenarios of the digital nomadic lifestyle.
            </p>
          </section>
          <section className="process-section">
            <span className="project-step-number">02</span>
            <h2>process</h2>
            <div className="process-image">
              <Img fluid={props.data.tecnomad_process.childImageSharp.fluid} />
            </div>
            <p className="full-text">
              An understanding of the digital nomad was established which then
              allowed us to form a Digital Nomad Persona.
            </p>
            <div className="image">
              <Img fluid={props.data.tnmprocess3.childImageSharp.fluid} />
              <Img fluid={props.data.tnmprocess4.childImageSharp.fluid} />
              <Img fluid={props.data.tnmprocess5.childImageSharp.fluid} />
            </div>
            <h4>BEHAVIOUR </h4>
            <p className="full-text">
              Keep moving, working, travelling and trying out new things.
              Growing up with the internet and digital tools means living and
              working differently.
            </p>
            <h4>PAINS</h4>
            <p className="full-text">
              Keeping in touch with family & friends. Understanding, adapting
              and adjusting to the different environments, cultures, people, &
              lifestyles. Privacy & security issues/expectations for different
              contexts.
            </p>
            <h4>NEEDS </h4>
            <p className="full-text">
              Their personal preferences/data to follow them everywhere they go.
              An space/product/service that is designed for diversity and
              inclusion Cosy environment that can be easily personalised.
            </p>
            <h4>GOALS </h4>
            <p className="full-text">
              Getting the most out of the place & from the people around for the
              time they are there. Feel like home anytime and anywhere they go
              (every corner of the globe). mad was established which then
              allowed us to form a Digital Nomad Persona.
            </p>
            <div className="image">
              <Img fluid={props.data.tnmprocess6.childImageSharp.fluid} />
            </div>
            <p className="full-text">
              4 Digital Nomadic contexts were explored; Travel, living, work &
              entertainment. Analysing the research gave us a key insight.
            </p>
            <h4>MAIN INSIGHT </h4>
            <p className="full-text">
              Data, privacy & security is not ON/OFF, but it is a gradient.
              There is a trade-off between personal data shared in return for
              contextual & personalised value. However, it is vital for
              businesses to be transparent about how their users’ data is being
              used when providing them value and should allow for user autonomy.{' '}
            </p>
            <h4>IDEA/CONCEPT </h4>
            <p className="full-text">
              To understand which kind of data are people willing and
              comfortable to share in exchange for receiving different value
              added services, as they move from one context to another.
            </p>
            <p className="full-text">
              This exhibition would provide people with apparent values they
              would get in exchange for sharing their data and thus assess how
              this level of transparency & user autonomy will affect how
              comfortable they feel in sharing their personal data.{' '}
            </p>
            <p className="full-text">
              The values will be centered around the four main goals/needs of a
              Digital Nomadic person:
            </p>
            <div className="full-text">
              <ol>
                <li>Keeping in touch</li>
                <li>Personalisation</li>
                <li>Feeling comfortable & “at home”</li>
                <li>
                  Getting inspired, finding new ideas and meeting new people
                </li>
              </ol>
            </div>
            <h4>ADDRESSED TOPICS </h4>
            <p className="full-text">
              Security & Privacy issues, Portability of personal data, provision
              of Contextually-Aware and Personalised value to users.
            </p>
            <div className="image">
              <Img fluid={props.data.tnmprocess7.childImageSharp.fluid} />
            </div>
            <h4>EXHIBITION SETTING </h4>
            <p className="full-text">
              Visitors will get to know the different categories of data they
              own. The exhibition space will be divided into multiple smaller
              areas which would represent a typical day (or a month) in the life
              of a nomadic person.
            </p>
            <div className="image">
              <Img fluid={props.data.tnmprocess8.childImageSharp.fluid} />
            </div>
            <p className="full-text">
              Visitors will have to answer some questions related to their data,
              like they were really embracing a nomadic lifestyle. Finally, they
              would get to interact with few products that are perfect for a
              person with a nomadic lifestyle. Visitors should be able to
              express their thoughts and opinions in regards data sharing and
              privacy in a fun, interactive and tangible manner.
            </p>
            <div id="project-images-wrapper">
              <Slider className="image-scroll" {...settings}>
                <Img fluid={props.data.tnmprocess9.childImageSharp.fluid} />
                <Img fluid={props.data.tnmprocess10.childImageSharp.fluid} />
                <Img fluid={props.data.tnmprocess11.childImageSharp.fluid} />
                <Img fluid={props.data.tnmprocess12.childImageSharp.fluid} />
              </Slider>
            </div>
          </section>
          <section className="outcome-section">
            <div className="outcome-text-wrapper">
              <span className="project-step-number">03</span>
              <h2>outcome</h2>
              <p>
                The exhibition explored how privacy & security concerns affected
                the amount of personal data the user is comfortable sharing at
                these four contexts.
              </p>
              <p>
                Participants were asked to place a stamp on the service or
                product they would like to use, giving in exchange the stated
                personal data. 4 groups of data were crossed with 4 contexts,
                resulting in 16 values that were offered to participants.{' '}
              </p>
              <p>
                The Question? Would you give the following personal data in
                exchange for different value in each of these contexts (that are
                encountered by a digital nomad)?
              </p>
              <h3>
                The exhibition was a huge success as it raised awareness amongst
                people in regards to the value of their personal data. It also
                showed the relationship between personal data and value
                different people have in different contexts and how open the
                visitors are to sharing their personal data in different
                contexts in exchange for receiving personalised service.
              </h3>
              <p>
                Visit{' '}
                <a href="https://thingsconmilan.com">thingsconmilan.com</a> to
                find out more about the entire event.
              </p>
            </div>

            <div id="project-images-wrapper">
              <Slider className="image-scroll" {...settings}>
                <Img fluid={props.data.tnmoutcome13.childImageSharp.fluid} />
                <Img fluid={props.data.tnmoutcome14.childImageSharp.fluid} />
                <Img fluid={props.data.tnmoutcome15.childImageSharp.fluid} />
                <Img fluid={props.data.tnmoutcome16.childImageSharp.fluid} />
                <Img fluid={props.data.tnmoutcome17.childImageSharp.fluid} />
                <Img fluid={props.data.tnmoutcome18.childImageSharp.fluid} />
                <Img fluid={props.data.tnmoutcome19.childImageSharp.fluid} />
              </Slider>
            </div>
            <div className="image">
              <Img fluid={props.data.tnmoutcome20.childImageSharp.fluid} />
            </div>
          </section>
          <section id="footertopcta" className="inner">
            <p className="text arrow-back">
              head back to
              <br />
              <Link to="/work">all my work.</Link>
            </p>
          </section>
        </div>
      </div>
    </div>
    <div className="footer-top">
      <div className="inner">
        <h2>other projects.</h2>
        <div id="work">
          <div className="card-wrapper">
            <a href="/work/service-design-kin">
              <div className="image">
                <Img fluid={props.data.project1.childImageSharp.fluid} alt="" />
              </div>
              <div className="card-info">
                <div className="title">kin.</div>
                <div className="text">
                  Conversational-based service for Type II Diabetes management
                  that leverages on trusted relationships.
                </div>
                <div className="tags">
                  <ul>
                    <li>SERVICE DESIGN</li>
                    <li>UX STRATEGY</li>
                    <li>USER EXPERIENCE</li>
                  </ul>
                </div>
              </div>
            </a>
          </div>
          <div className="card-wrapper">
            <a href="/work/service-design-levico-terme">
              <div className="image">
                <Img fluid={props.data.project2.childImageSharp.fluid} alt="" />
              </div>
              <div className="card-info">
                <div className="title">levico terme medical centre.</div>
                <div className="text">
                  Using gamification techniques to provide a holistic &
                  customised sports service performance & rehabilitation.
                </div>
                <div className="tags">
                  <ul>
                    <li>SERVICE DESIGN</li>
                    <li>USER EXPERIENCE</li>
                    <li>UX STRATEGY</li>
                    <li>BUSINESS DESIGN</li>
                  </ul>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default ServiceDesignTNM

export const fluidTNMImage = graphql`
  fragment fluidTNMImage on File {
    childImageSharp {
      fluid(maxWidth: 920, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    tnmcover: file(
      relativePath: { eq: "projects/service/tech/cover/tech-cover-image.jpg" }
    ) {
      ...fluidTNMImage
    }
    casa_jasmina: file(
      relativePath: { eq: "projects/service/tech/casa-jasmina.jpg" }
    ) {
      ...fluidTNMImage
    }
    gh_logo: file(relativePath: { eq: "projects/service/tech/gh_logo.jpg" }) {
      ...fluidTNMImage
    }
    salone_del_mobile: file(
      relativePath: { eq: "projects/service/tech/Salone-del-Mobile_FNM.jpg" }
    ) {
      ...fluidTNMImage
    }
    things_logo: file(
      relativePath: { eq: "projects/service/tech/things-logo.jpg" }
    ) {
      ...fluidTNMImage
    }
    thingscon_milan: file(
      relativePath: { eq: "projects/service/tech/thingscon-milan.jpg" }
    ) {
      ...fluidTNMImage
    }
    tnmchallenge1: file(
      relativePath: { eq: "projects/service/tech/challenge/1.jpg" }
    ) {
      ...fluidTNMImage
    }
    tnmchallenge2: file(
      relativePath: { eq: "projects/service/tech/challenge/2.jpg" }
    ) {
      ...fluidTNMImage
    }
    tnmprocess3: file(
      relativePath: { eq: "projects/service/tech/process/3.jpg" }
    ) {
      ...fluidTNMImage
    }
    tnmprocess4: file(
      relativePath: { eq: "projects/service/tech/process/4.jpg" }
    ) {
      ...fluidTNMImage
    }
    tnmprocess5: file(
      relativePath: { eq: "projects/service/tech/process/5.jpg" }
    ) {
      ...fluidTNMImage
    }
    tnmprocess6: file(
      relativePath: { eq: "projects/service/tech/process/6.jpg" }
    ) {
      ...fluidTNMImage
    }
    tnmprocess7: file(
      relativePath: { eq: "projects/service/tech/process/7.jpg" }
    ) {
      ...fluidTNMImage
    }
    tnmprocess8: file(
      relativePath: { eq: "projects/service/tech/process/8.jpg" }
    ) {
      ...fluidTNMImage
    }
    tnmprocess9: file(
      relativePath: { eq: "projects/service/tech/process/9.jpg" }
    ) {
      ...fluidTNMImage
    }
    tnmprocess10: file(
      relativePath: { eq: "projects/service/tech/process/10.jpg" }
    ) {
      ...fluidTNMImage
    }
    tnmprocess11: file(
      relativePath: { eq: "projects/service/tech/process/11.jpg" }
    ) {
      ...fluidTNMImage
    }
    tnmprocess12: file(
      relativePath: { eq: "projects/service/tech/process/12.jpg" }
    ) {
      ...fluidTNMImage
    }
    tnmoutcome13: file(
      relativePath: { eq: "projects/service/tech/outcome/13.jpg" }
    ) {
      ...fluidTNMImage
    }
    tnmoutcome14: file(
      relativePath: { eq: "projects/service/tech/outcome/14.jpg" }
    ) {
      ...fluidTNMImage
    }
    tnmoutcome15: file(
      relativePath: { eq: "projects/service/tech/outcome/15.jpg" }
    ) {
      ...fluidTNMImage
    }
    tnmoutcome16: file(
      relativePath: { eq: "projects/service/tech/outcome/16.jpg" }
    ) {
      ...fluidTNMImage
    }
    tnmoutcome17: file(
      relativePath: { eq: "projects/service/tech/outcome/17.jpg" }
    ) {
      ...fluidTNMImage
    }
    tnmoutcome18: file(
      relativePath: { eq: "projects/service/tech/outcome/18.JPG" }
    ) {
      ...fluidTNMImage
    }
    tnmoutcome19: file(
      relativePath: { eq: "projects/service/tech/outcome/19.JPG" }
    ) {
      ...fluidTNMImage
    }
    tnmoutcome20: file(
      relativePath: { eq: "projects/service/tech/outcome/20.png" }
    ) {
      ...fluidTNMImage
    }
    tecnomad_process: file(
      relativePath: { eq: "projects/process/tecnomad-process.png" }
    ) {
      ...fluidTNMImage
    }
    project1: file(relativePath: { eq: "projects/6.png" }) {
      ...fluidTNMImage
    }
    project2: file(relativePath: { eq: "projects/7.png" }) {
      ...fluidTNMImage
    }
  }
`
